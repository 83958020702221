import {mapActions} from 'vuex'

export const shoppingCartMixin = {
  methods: {
    ...mapActions('shoppingCart', [
      'updateQuantityFrontend',
      'deleteCartItemFrontend',
      'clearCartFrontend',
    ]),
    async deleteProduct(product) {
      await this.deleteCartItemFrontend(product.id).then((response) => {
        this.$toast.success(
          `${product.itemTypeName} has been successfully deleted from the cart.`
        )
      })
    },
    async increaseQuantity(product) {
      product.quantity++
      await this.updateQuantityFrontend({
        id: product.id,
        quantity: product.quantity,
      })
    },
    async decreaseQuantity(product) {
      if (product.quantity >= 2) {
        product.quantity--
        await this.updateQuantityFrontend({
          id: product.id,
          quantity: product.quantity,
        })
      }
    },
    async clearCart() {
      await this.clearCartFrontend().then((response) => {
        this.$toast.success(`Cart cleared successfully!`)
      })
    },
  },
}
