import moment from 'moment'
import {mapGetters} from 'vuex'
import {codes} from '@/enums/formatSettings'
import {adminCodes} from '@/enums/adminSettings'
export const columnFormatterMixin = {
  computed: {
    ...mapGetters('common/formatter', ['getFormattingSettings']),
    ...mapGetters('backoffice/settings', ['adminSettingByCode']),
  },
  methods: {
    formatCurrency(price, decimalPlaces = 2) {
      const priceFixed =
        Number(price) <= 0 ? 0 : Number(Number(price).toFixed(decimalPlaces))
      const formattedPriceStr =
        this.getFormattingSettings({code: codes.CURRENCY}) +
        priceFixed.toLocaleString('en-US', {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        })
      return formattedPriceStr
    },
    ellipsize(input, atLength) {
      if (input == null || input === '' || input.length < atLength) {
        return input
      }
      return input.substring(0, atLength) + '...'
    },
    formatDate(str) {
      const format = this.adminSettingByCode(adminCodes.default_date_format)
      const minDate = moment.utc('01/01/0001')
      if (format && format.value) {
        if (moment.utc(str).isAfter(minDate))
          return moment(str).format(format.value)
        return '-'
      }
      else {
        return moment(str).format('MM/DD/YYYY')
      }
    },
  },
}
