import { render, staticRenderFns } from "./shopping-cart-sidebar.vue?vue&type=template&id=3b37cff6&scoped=true&"
import script from "./shopping-cart-sidebar.vue?vue&type=script&lang=js&"
export * from "./shopping-cart-sidebar.vue?vue&type=script&lang=js&"
import style0 from "./shopping-cart-sidebar.vue?vue&type=style&index=0&id=3b37cff6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../MultiSiteClient/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b37cff6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../MultiSiteClient/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
