//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapActions} from 'vuex'
import {columnFormatterMixin} from '@/mixins/columnFormatterMixin'
import {shoppingCartMixin} from '@/mixins/shoppingCartMixin'

export default {
  name: 'ShoppingCartSidebar',
  mixins: [columnFormatterMixin, shoppingCartMixin],
  data() {
    return {
      subtotal: 0,
    }
  },
  computed: {
    ...mapState('shoppingCart', [
      'cartSideOpen',
      'cartItems',
      'customerAddresses',
    ]),
  },
  watch: {
    cartItems: {
      handler(newValue) {
        this.updateSubTotal()
      },
      deep: true,
    },
  },
  async mounted() {
    await this.frontendGetBySessionId()
  },
  methods: {
    ...mapActions('shoppingCart', [
      'setCartSideOpen',
      'frontendGetBySessionId',
    ]),
    closeCartSide() {
      this.setCartSideOpen(false)
    },
    async proceedToCheckout() {
      this.closeCartSide()
      await this.$router.push('checkout')
    },
    updateSubTotal() {
      let subtotal = 0
      this.cartItems.forEach((cartItem) => {
        subtotal += cartItem.subTotal
      })
      this.subtotal = subtotal
    },
  },
}
